import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";

const BowelCancer = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					img: file(relativePath: { eq: "bowel-cancer.jpg" }) {
						childImageSharp {
							fluid(maxHeight: 400) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			`}
			render={({ img }) => (
				<Blog
					title="Bowel Cancer Screening"
					img={<Img fluid={img.childImageSharp.fluid} alt="Bowel Cancer Screening" />}
					imgWidth={img}
				>
					<SEO
						title="Bowel Cancer Screening"
						keywords={[
							`Bowel cancer`,
							`Bowel cancer screening`,
							`Polyps`,
							`Gastroenterology`,
							`Bowel Cancer Screening Programme`,
							`Dr Frank Brennan`,
							`Dr Spiro Raftopoulos`,
							`Dr Ian Yusoff`,
							`Dr Dev Segarajasingam`,
							`Dr Sam Rao`,
							`Dr Claire Gordon`,
							`Dr Duy Pham`,
						]}
						description="Useful information about bowel cancer screening in Australia"
						type="article"
					/>
					<Par text="Bowel cancer affected more than 14,200 Australians in 2007 and more than 4,000 died from it making it the second leading cancer killer. At the age of 50 you have approximately a 1 in 100 chance of developing bowel cancer in the following 10 years and by the age of 60 the risk is 1 in 100 in the following 5 years. If you have a family history then these risks are increased. The majority of bowel cancer (possibly up to 99%) begins in a polyp which is a benign growth in the colon. The polyp to cancer sequence of development, which may be 10-15 years, gives a potential window of opportunity during which colonoscopy can remove polyps and so prevent cancer or the cancer can be detected while it is still curable. The national Bowel Cancer Screening Programme (NBCSP) uses a test to detect microscopic amounts of blood in the stool and so helps to target that section of the population who may benefit most from having a colonoscopy." />
					<Par text="It is apparent that while colonoscopy is effective in reducing deaths from bowel cancer there have been limitations to it’s effectiveness. There has been increasing awareness of the potential for “missed lesions” but there have also been significant developments to help reduce this risk. These developments include improved preparation of the colon, use of high definition wide angle colonoscopes and video equipment, carbon dioxide insufflation of the colon, retroflexion of the endoscope to look behind folds as well as the assessment of withdrawal time and adenoma detection rates by the endoscopist. We have also learned to identify lesions (sessile serrated adenomas) that were previously felt to be innocuous or innocent but we now realise that they do identify patients at increased risk of subsequent malignancy. All these developments have been incorporated into the practice of colonoscopy by the Gastroenterologists practicing at Advanced GI WA." />
				</Blog>
			)}
		/>
	);
};

export default BowelCancer;
